import { Component, Input, OnInit } from '@angular/core';
import { ClientEquipment, UnsavedClientEquipment } from '@fullyops/legacy/data';
import { BehaviorSubject } from 'rxjs';
import { ClientEquipmentFormService } from './client-equipment-form.service';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import { FormComponent } from '@fullyops/legacy/ui/ui-shared/utils/form.component';
import { PermissionType } from '@fullyops/legacy/ui/ui-shared/utils/crm-types';
import { UiAuthService } from '../ui-auth.service';
import { ToastService } from '@fullyops/shared/services/toast/toast.service';

@Component({
  selector: 'crm-client-equipment-form',
  templateUrl: './client-equipment-form.component.html',
  styleUrls: ['./client-equipment-form.component.scss'],
  providers: [ClientEquipmentFormService],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class ClientEquipmentFormComponent
  extends FormComponent<
    ClientEquipment,
    UnsavedClientEquipment,
    ClientEquipmentFormService
  >
  implements OnInit
{
  @Input() companyId: string;

  constructor(
    public clientEquipmentFormService: ClientEquipmentFormService,
    public toastService: ToastService,
    public authService: UiAuthService
  ) {
    super(toastService, authService);

    this.formService = clientEquipmentFormService;
    this.formEditPermissions = [PermissionType.CAN_EDIT_COMPANIES];
  }

  ngOnInit() {
    this.draft$ = new BehaviorSubject<UnsavedClientEquipment>(
      new UnsavedClientEquipment(
        '',
        null,
        '',
        null,
        '',
        false,
        '',
        '',
        '',
        '',
        '',
        null,
        ''
      )
    );

    this.initForm();
    this.initFields();

    this.actions.warn = null;
  }

  initFields() {
    this.formFields = [
      { name: 'brand', label: 'brand', type: 'text', size: 4 },
      { name: 'model', label: 'model', type: 'text', size: 4 },
      { name: 'serialNumber', label: 'serialNumber', type: 'text', size: 4 },
      { name: 'warranty', label: 'warranty', type: 'date', size: 4 },
    ];
  }
}
