import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { InterventionPostRequest } from '@fullyops/legacy/data/api/types/Intervention';
import { AutocompleteListItemType } from '@fullyops/legacy/ui/ui-shared/form-autocomplete-list/form-autocomplete-list.component';
import { SelectOptionType } from '@fullyops/legacy/ui/ui-shared/form-select/form-select.component';
import { BehaviorSubject } from 'rxjs';
import { AnomalyController } from '../../../ui-anomaly-controller.service';
import { TemplateFormFields } from '@fullyops/legacy/ui/ui-shared/form-template/form-template.component';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { UiAuthService } from '../../../ui-auth.service';
import {
  ActionForm,
  ActionInjectionData,
  NEW_ACTION_TOKEN,
} from '@fullyops/legacy/ui/ui-crm/work-order-action/actions-forms/action-form-component.component';
import { ToastService } from '@fullyops/shared/services/toast/toast.service';

export type ActionCorrectiveFormType = FormGroup<{
  title: FormControl<string>;
  anomalies: FormControl<SelectOptionType[]>;
  equipment: FormControl<SelectOptionType>;
  parts: FormControl<AutocompleteListItemType[]>;
  uncataloguedParts: FormControl<AutocompleteListItemType[]>;
  description: FormControl<string>;
  userObservation: FormControl<string>;
  internalObservation: FormControl<string>;
}>;

export type CreateCorrectiveActionType = {
  intervention: InterventionPostRequest;
  correctiveActionForm: ActionCorrectiveForm;
};

export type EditCorrectiveAction = {
  intervention: InterventionPostRequest;
};

@Component({
  selector: 'crm-action-corrective-form',
  templateUrl: './action-corrective-form.component.html',
  styleUrls: ['./action-corrective-form.component.scss'],
})
export class ActionCorrectiveForm extends ActionForm implements OnInit {
  clientEquipmentListOptions$: BehaviorSubject<SelectOptionType[]> =
    this.data.clientEquipmentListOptions$;
  anomaliesOptions$: BehaviorSubject<SelectOptionType[]> =
    this.data.anomaliesOptions$;
  partOptions$: BehaviorSubject<SelectOptionType[]> = this.data.partOptions$;
  uncataloguedPartsOptions$: BehaviorSubject<SelectOptionType[]> =
    this.data.uncataloguedPartsOptions$;
  showUncataloguedPart: boolean = this.data.showUncataloguedPart;

  public readonly title = this.isNew
    ? 'ACTION_SIDEBAR_CREATE'
    : 'ACTION_SIDEBAR_EDIT';

  constructor(
    private formBuilder: FormBuilder,
    private anomalyController: AnomalyController,
    @Inject(NEW_ACTION_TOKEN) data: ActionInjectionData,
    toastService: ToastService,
    auth: UiAuthService
  ) {
    super(toastService, auth, data);
  }

  formGroup: ActionCorrectiveFormType = this.formBuilder.group({
    title: this.formBuilder.control('', Validators.required),
    anomalies: this.formBuilder.control([]),
    equipment: this.formBuilder.control(null, Validators.required),
    parts: this.formBuilder.control([]),
    uncataloguedParts: this.formBuilder.control([]),
    description: this.formBuilder.control('', Validators.required),
    internalObservation: this.formBuilder.control(''),
    userObservation: this.formBuilder.control(''),
  });

  formFields: TemplateFormFields<ActionCorrectiveFormType> = [];

  ngOnInit(): void {
    super.ngOnInit();
    this.initFormValues();
    this.initFormFields();
  }

  initFormValues() {
    if (!this.isNew) {
      const intervention = this.intervention;
      const equipment = intervention.equipment
        ? {
            id: intervention?.equipment?.equipment?.id,
            name: intervention?.equipment?.equipment?.name,
          }
        : null;

      this.formGroup.reset({
        anomalies: intervention.anomalies.map((anomaly) => ({
          id: anomaly.id,
          name: anomaly.description,
        })),
        description: intervention.description,
        equipment,
        internalObservation: intervention.internalObservation,
        parts: intervention.parts.map((part) => ({
          itemData: part.part,
          quantity: part.quantity,
        })),
        title: intervention.title,
        uncataloguedParts: intervention.uncataloguedParts.map((uPart) => ({
          itemData: uPart.uncataloguedPart,
          quantity: uPart.quantity,
        })),
        userObservation: intervention.userObservation,
      });
    } else {
      this.formGroup.reset({
        anomalies: [],
        description: '',
        internalObservation: '',
        equipment: null,
        parts: [],
        uncataloguedParts: [],
        userObservation: '',
        title: '',
      });
    }
  }

  initFormFields() {
    this.formFields = [
      { label: 'title', name: 'title', type: 'text', size: 12 },
      {
        name: 'equipment',
        label: 'equipment',
        type: 'autocomplete',
        size: 12,
        items$: this.clientEquipmentListOptions$,
        autocomplete: {
          firstOption: {
            value: 'newMachine',
            label: 'newMachine',
            fontAwesomeIcon: faPlusCircle,
            iconColor: 'green',
            onClick: () => {
              this.data.onCreateMachineByPopUp(
                this.formGroup.controls['equipment']
              );
            },
          },
        },
      },
      {
        name: 'anomalies',
        label: 'anomalies',
        type: 'chips-autocomplete',
        size: 12,
        items$: this.anomaliesOptions$,
        autocomplete: {
          onInputChange: {
            debounceTime: 500,
            callback: (e) => {
              this.anomalyController.refetchByName({ description: e });
            },
          },
          onTypeEnter: {
            callback: async ({ chipsAutocompleteCtrl, inputElement }) => {
              await this.anomalyController.addAnomalyOnForm({
                description: inputElement.value,
                formControl: this.formGroup.controls['anomalies'],
                chipsAutocompleteCtrl,
                inputElement,
              });
            },
          },
          chipsDisplayWith: ({ name }: SelectOptionType) => name,
          saveFullOptionValue: true,
        },
      },
      {
        label: 'parts',
        name: 'parts',
        type: 'autocomplete-list',
        items$: this.partOptions$,
        size: 12,
        autocomplete: {
          firstOption: {
            label: 'requestPart',
            fontAwesomeIcon: faPlusCircle,
            iconColor: 'green',
            onClick: this.navigateToRequestTab,
          },
          onTypeEnter: {
            callback: (e) =>
              this.data.onCreatePart({
                partName: e.inputElement.value,
                formControl: this.formGroup.controls['parts'],
                addFormControl: e.formControl,
              }),
          },
          onInputChange: {
            debounceTime: 300,
            callback: (e) => this.data.onUpdatePartsList(e.newItem),
          },
        },
      },
      {
        label: 'uncataloguedParts',
        name: 'uncataloguedParts',
        type: 'autocomplete-list',
        items$: this.uncataloguedPartsOptions$,
        size: 12,
        hidden: !this.showUncataloguedPart,
        autocomplete: {
          onTypeEnter: {
            callback: (e) =>
              this.data.onCreateUncataloguedPart({
                partName: e.inputElement.value,
                formControl: this.formGroup.controls['uncataloguedParts'],
                addFormControl: e.formControl,
              }),
          },
          onInputChange: {
            debounceTime: 300,
            callback: (e) => this.data.onUpdateUncataloguedPartsList(e.newItem),
          },
        },
      },
      { label: 'workDone', name: 'description', type: 'textarea', size: 12 },
      {
        label: 'userObservation',
        name: 'userObservation',
        type: 'textarea',
        size: 12,
      },
      {
        label: 'internalObservation',
        name: 'internalObservation',
        type: 'textarea',
        size: 12,
      },
    ];
  }

  onCreateAction() {
    this.data.onCreateAction({
      intervention: this.formatToRequest(),
      correctiveActionForm: this,
    });
  }

  onEditAction() {
    this.data.onEditAction({ intervention: this.formatToRequest() });
  }

  formatToRequest(): InterventionPostRequest {
    const { controls } = this.formGroup;
    const interventionId = this.intervention?.id || null;

    const newInterventionData: InterventionPostRequest = {
      anomalies: controls.anomalies.value.map(({ id }) => ({
        anomalyId: id,
        interventionId,
      })),
      closedAt: this.intervention?.closedAt || null,
      description: controls.description.value,
      equipment: {
        equipmentId: controls.equipment.value.id,
        interventionId: this.intervention?.id || null,
      },
      id: interventionId,
      internalObservation: controls.internalObservation.value,
      parts:
        controls.parts?.value?.map(({ itemData, quantity }) => ({
          interventionId,
          partId: itemData.id,
          quantity,
        })) || [],
      supportTicketId: this.ticket.id,
      uncataloguedParts:
        controls.uncataloguedParts?.value?.map(({ itemData, quantity }) => ({
          interventionId,
          quantity,
          uncataloguedPartId: itemData.id,
        })) || [],
      userObservation: controls.userObservation.value,
      userParts: null,
      type: this.ticket.interventionType,
      title: controls.title.value,
    };

    return newInterventionData;
  }

  cancelForm() {
    this.data.onCancel();
  }
}
