import { Component, Input, OnInit } from '@angular/core';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import { PermissionType } from '@fullyops/legacy/ui/ui-shared/utils/crm-types';
import { AnomalyFormService } from './anomaly-form.service';
import { Anomaly, UnsavedAnomaly } from '@fullyops/legacy/data';
import { FormComponent } from '@fullyops/legacy/ui/ui-shared/utils/form.component';
import { BehaviorSubject } from 'rxjs';
import { UiAuthService } from '../ui-auth.service';
import { ToastService } from '@fullyops/shared/services/toast/toast.service';

@Component({
  selector: 'crm-anomaly-form',
  templateUrl: './anomaly-form.component.html',
  styleUrls: ['./anomaly-form.component.scss'],
  providers: [AnomalyFormService],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class AnomalyFormComponent
  extends FormComponent<Anomaly, UnsavedAnomaly, AnomalyFormService>
  implements OnInit
{
  constructor(
    public anomalyFormService: AnomalyFormService,
    public toastService: ToastService,
    public authService: UiAuthService
  ) {
    super(toastService, authService);

    this.formService = anomalyFormService;
    this.formEditPermissions = [PermissionType.CAN_EDIT_CLIENT_EQUIPMENTS];
    this.draft$ = new BehaviorSubject<UnsavedAnomaly>(
      new UnsavedAnomaly('', null, '', '', [''], '', '', null)
    );
  }

  ngOnInit() {
    this.initForm();
    this.initFields();
  }

  initFields() {
    this.formFields = [
      { name: 'type', type: 'text', label: 'anomalyType', size: 12 },
      { name: 'description', type: 'text', label: 'description', size: 12 },
    ];
  }
}
