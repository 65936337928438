import { Injectable } from '@angular/core';
import { ApiRequestsAdapterService } from '@fullyops/data/requests/api-adapter.service';
import { createStore } from '@ngneat/elf';
import {
  selectAllEntities,
  setEntities,
  updateEntities,
  upsertEntitiesById,
  withEntities,
} from '@ngneat/elf-entities';
import { map, Observable, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RequestsService {
  constructor(private api: ApiRequestsAdapterService) {}

  private statusStore = createStore(
    { name: 'request-statuses' },
    withEntities<any>({ idKey: 'id' })
  );

  private ticketStore = createStore(
    { name: 'requests-tickets' },
    withEntities<any>({ idKey: 'id' })
  );

  requestStatus$ = this.statusStore.pipe(selectAllEntities());
  requestTickets$ = this.ticketStore.pipe(selectAllEntities());

  getById(id: string): Observable<any> {
    return this.refreshTicket(id);
  }

  deleteTicket(id: string): Observable<void> {
    return this.api.delete(id);
  }

  refreshTicket(id: string) {
    return this.api.getById({ id }).pipe(
      tap((res: any) => {
        this.ticketStore.update(
          upsertEntitiesById(id, { updater: res, creator: () => res })
        );
      })
    );
  }

  getAllStatus() {
    return this.api
      .getRequestTicketStatuses()
      .pipe(
        map((res: any) => res?.results.sort((a, b) => a.position - b.position))
      )
      .subscribe((res: any) => {
        this.statusStore.update(setEntities(res));
      });
  }

  refreshAll() {
    this.api.get().subscribe((res: any) => {
      this.ticketStore.update(setEntities(res?.results));
    });
  }

  updateStatus(ticketId, status) {
    this.ticketStore.update(
      updateEntities(ticketId, (so) => ({ ...so, status }))
    );
    this.api
      .updateById({ newData: { statusId: status.id }, ticketId })
      .subscribe(() => {
        this.refreshAll();
      });
  }

  mapFormData(formData) {
    const blankClientInfo = {
      address: '',
      zipCode: '',
      distance: 'null',
      city: '',
      countryISOCode: '',
    };
    return {
      attachments: [],
      ...formData,
      ticketId: formData?.id,
      assigneeIds:
        formData.assignees?.length > 0
          ? formData.assignees.map((a) => a.id)
          : null,
      parts: formData.parts.map((p) => {
        return {
          partId: p.part.id,
          quantity: p.quantity,
          requestTicketId: formData.id,
        };
      }),
      uncataloguedParts: formData.uncataloguedParts.map((p) => {
        return {
          quantity: p.quantity,
          requestTicketId: p.requestTicketId,
          uncataloguedPartId: p.uncataloguedPart.id,
        };
      }),
      contactIds: [],
      clientInfo: formData.supplier || blankClientInfo,
      statusId: formData.status,
      supplierId:
        formData.supplier?.supplier == null
          ? null
          : formData.supplier.supplier.id,
      supportTicketId:
        formData.supportTicketId == null ? null : formData.supportTicketId.id,
    };
  }

  updateTicket(formData) {
    const requestData = this.mapFormData(formData);
    this.api
      .updateById({ newData: requestData, ticketId: formData.id })
      .subscribe((res) => {});
  }

  createTicket(formData): Observable<string> {
    const requestData = this.mapFormData(formData);
    return this.api
      .createNew(requestData)
      .pipe(map((newRequest) => newRequest.id));
  }

  uploadFile(file: File) {
    return this.api.uploadImage(file);
  }

  getFile(id: string): Observable<{ url: string }> {
    return this.api.getImage(id);
  }

  sendTicketEmail(params: { id: string; emailsRecipes: string[] }) {
    return this.api.sendTicketEmail(params);
  }

  getRequestColors() {
    return {
      'In Queue': '#e1f5fe',
      Processing: '#ffecb3',
      Cancelled: '#ffccbc',
      Done: '#f0f4c3',
    };
  }
}
