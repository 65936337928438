<mat-dialog-content class="dialog-content">
  <div class="header">
    <h2>{{ 'report' | i18nextCap }}</h2>

    <button mat-icon-button (click)="onLeave()">
      <div class="icon blue-icon">
        <fa-icon [icon]="faCircleXmark" size="2x"></fa-icon>
      </div>
    </button>
  </div>

  <ngx-extended-pdf-viewer
    [src]="data.document"
    [language]="lang"
    [showToolbar]="true"
    [showSidebarButton]="true"
    [showFindButton]="false"
    [showPagingButtons]="true"
    [showDrawEditor]="false"
    [showTextEditor]="false"
    [showZoomButtons]="true"
    [showPresentationModeButton]="true"
    [showOpenFileButton]="false"
    [showPrintButton]="true"
    [showDownloadButton]="true"
    [filenameForDownload]="data.filename"
    [showSecondaryToolbarButton]="true"
    [showRotateButton]="false"
    [showHandToolButton]="false"
    [showScrollingButton]="false"
    [showSpreadButton]="false"
    [showPropertiesButton]="false"
    [zoom]="getZoomType()"
    class="doc-viewer"
    *ngIf="data.document"
  ></ngx-extended-pdf-viewer>
</mat-dialog-content>
