import { Component } from '@angular/core';
import { MaterialModule } from '@fullyops/shared/material.module';
import { I18NextModule } from 'angular-i18next';

@Component({
  standalone: true,
  imports: [MaterialModule, I18NextModule],
  templateUrl: './unsaved-changes-dialog.component.html',
})
export class UnsavedChangesDialogComponent {}
