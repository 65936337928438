import { Component, Inject, OnInit } from '@angular/core';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { LocaleService } from '@fullyops/shared/services/locale/locale.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  Breakpoints,
  ScreenBreakpointObserverService,
} from '@fullyops/shared/services/screen-breakpoints/screen-breakpoint-observer.service';

export interface DialogData {
  document: any;
  filename: string;
}

type DialogZoomType = 'page-width' | 'auto';

@Component({
  selector: 'crm-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogDocumentViewComponent {
  constructor(
    private localeService: LocaleService,
    public dialogRef: MatDialogRef<DialogDocumentViewComponent>,
    private screenBreakpointObserverService: ScreenBreakpointObserverService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.lang = localeService.getCurrentLanguage();
  }

  faCircleXmark = faCircleXmark;
  lang: string;

  getZoomType(): DialogZoomType {
    const activeBreakpoints =
      this.screenBreakpointObserverService.screenSizeObserver.value;

    // Large screens
    if (
      activeBreakpoints.some((r) =>
        [Breakpoints.MD, Breakpoints.LG, Breakpoints.XL].includes(r)
      )
    ) {
      return 'auto';
    }

    return 'page-width';
  }

  onCancel(): boolean {
    this.dialogRef.close();
    return false;
  }

  onLeave(): boolean {
    this.dialogRef.close();
    return true;
  }
}
