import { Component, Inject, InjectionToken, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import {
  InterventionPostRequest,
  InterventionResponse,
} from '@fullyops/legacy/data/api/types/Intervention';
import { SupportTicketResponse } from '@fullyops/legacy/data/api/types/SupportTicket';
import { PermissionType, UiAuthService } from '@fullyops/legacy/ui';
import {
  CreatePreventiveActionType,
  EditPreventiveAction,
} from '@fullyops/legacy/ui/ui-crm/work-order-action/actions-forms/action-preventive-form/action-preventive-form.component';
import {
  CreateGeneralAction,
  EditGeneralAction,
} from '@fullyops/legacy/ui/ui-crm/work-order-action/actions-forms/action-general-form/action-general-form.component';
import {
  CreateCorrectiveActionType,
  EditCorrectiveAction,
} from '@fullyops/legacy/ui/ui-crm/work-order-action/actions-forms/action-corrective-form/action-corrective-form.component';
import {
  ActionCreatePart,
  ActionCreateUncataloguedPart,
} from '@fullyops/legacy/ui/ui-crm/work-order-action/work-order-actions/work-order-actions.component';
import { BehaviorSubject } from 'rxjs';
import { SelectOptionType } from '@fullyops/legacy/ui/ui-shared/form-select/form-select.component';
import { ToastService } from '@fullyops/shared/services/toast/toast.service';

export type CreateAction =
  | CreateGeneralAction
  | CreatePreventiveActionType
  | CreateCorrectiveActionType;
export type EditAction =
  | EditGeneralAction
  | EditPreventiveAction
  | EditCorrectiveAction;

export interface ActionInjectionData {
  ticket: SupportTicketResponse;
  intervention: InterventionResponse;
  isNew: boolean;
  ownerId: string;
  clientEquipmentListOptions$: BehaviorSubject<SelectOptionType[]>;
  anomaliesOptions$: BehaviorSubject<SelectOptionType[]>;
  partOptions$: BehaviorSubject<SelectOptionType[]>;
  uncataloguedPartsOptions$: BehaviorSubject<SelectOptionType[]>;
  onOpenRequestTab: () => void;
  onCreateAction: (action: CreateAction) => void;
  onEditAction: (action: EditAction) => void;
  onCreateMachineByPopUp: (event: FormControl) => void;
  index: number;
  showUncataloguedPart: boolean;
  onCreatePart: (part: ActionCreatePart) => void;
  onCreateUncataloguedPart: (
    uncataloguedPart: ActionCreateUncataloguedPart
  ) => void;
  onUpdatePartsList: (event: string) => void;
  onUpdateUncataloguedPartsList: (event: string) => void;
  createMachineByPopUp;
  onDelete: (id: string) => void;
  onCancel: () => void;
}

export const NEW_ACTION_TOKEN = new InjectionToken<ActionInjectionData>('data');

@Component({ template: '' })
export abstract class ActionForm implements OnInit {
  protected isNew: boolean = this.data.isNew;
  protected index: number = this.data.index;
  protected ticket: SupportTicketResponse = this.data.ticket;
  protected intervention: InterventionResponse = this.data.intervention;
  protected ownerId: string = this.data.ownerId;

  abstract formGroup: FormGroup;
  permissionToEditForm: PermissionType[] = [
    PermissionType.CAN_EDIT_OWN_INTERVENTIONS,
    PermissionType.CAN_EDIT_INTERVENTIONS,
  ];
  permissionToDeleteIntervention: PermissionType[] = [
    PermissionType.CAN_DELETE_OWN_INTERVENTIONS,
    PermissionType.CAN_DELETE_INTERVENTIONS,
  ];
  havePermissionToDeleteIntervention = false;

  constructor(
    public toastService: ToastService,
    public authService: UiAuthService,
    @Inject(null) protected data: ActionInjectionData
  ) {}

  ngOnInit(): void {
    this.disableFormIfCantEdit();
    this.setHavePermissionToDeleteIntervention();
  }

  setHavePermissionToDeleteIntervention() {
    if (this.ticket != undefined) {
      this.authService
        .hasPermission(this.permissionToDeleteIntervention, this.ownerId)
        .subscribe((permission) => {
          if (!permission) {
            this.havePermissionToDeleteIntervention = false;
          } else {
            this.havePermissionToDeleteIntervention = true;
          }
        });
    }
  }

  scrollToInvalidControl() {
    const firstInvalidControlKeyName = Object.keys(
      this.formGroup.controls
    ).find((key) => {
      const formControl = this.formGroup.controls[key] as AbstractControl;
      if (formControl.invalid) return true;
      return false;
    });

    const invalidControlEl = document.querySelectorAll(
      `[name="${firstInvalidControlKeyName}"]`
    )[this.index + 1];

    if (invalidControlEl) {
      invalidControlEl.scrollIntoView({ behavior: 'smooth' });
      if (invalidControlEl instanceof HTMLInputElement) {
        invalidControlEl.focus();
      }
    }
  }

  onDeleteAction(id: string) {
    this.data.onDelete(id);
  }

  navigateToRequestTab = () => {
    this.data.onOpenRequestTab();
  };

  onSubmit() {
    this.formGroup.markAllAsTouched();

    if (!this.formGroup.valid) {
      this.scrollToInvalidControl();

      this.toastService.showInvalidFieldsFormErrorMessage();
      return;
    }

    if (this.isNew) return this.onCreateAction();
    return this.onEditAction();
  }

  disableFormIfCantEdit() {
    this.authService
      .hasPermission(this.permissionToEditForm, this.ownerId)
      .subscribe((permission) => {
        if (!permission) {
          if (this.ticket != undefined) this.formGroup.disable();
        }
      });
  }

  getCreatedByName() {
    if (!this.intervention?.createdBy) return '';
    const { firstName, lastName } = this.intervention?.createdBy || {};
    if (firstName || lastName) return `${firstName} ${lastName}`;
    return '';
  }

  abstract onCreateAction(): any;

  abstract onEditAction(): any;

  abstract formatToRequest(): InterventionPostRequest;

  abstract initFormValues(): void;

  abstract initFormFields(): void;
}
