<fo-toolbar>
  <ng-container leftElements>
    <fo-breadcrumb
      [lastBreadcrumbLabelOverride]="
        user$?.value
          ? user$?.value.firstName + ' ' + user$?.value.lastName
          : null
      "
    ></fo-breadcrumb>
  </ng-container>
  <ng-container rightElements>
    <fo-action-buttons
      [actionButtons]="actions"
      (actionEvent)="onActionEvent($event)"
    ></fo-action-buttons>
  </ng-container>
</fo-toolbar>
<crm-container [maxSize]="'xl'">
  <form
    #ngForm="ngForm"
    [@inOut]="'in'"
    [formGroup]="formGroup"
    (ngSubmit)="onSubmit()"
  >
    <div class="form-row">
      <div class="col-md-8">
        <crm-form-template
          [formGroup]="formGroup"
          [formFields]="formFields"
        ></crm-form-template>
        <div class="margin-fix">
          <button type="button" mat-flat-button color="primary" type="submit">
            {{ 'forms:' + 'save' | i18nextCap }}
          </button>
        </div>
      </div>
      <div class="col-md-4">
        <crm-history
          [history$]="$any(history$)"
          (openActivityEvent)="onOpenActivity($event)"
          (openTicketEvent)="onOpenTicket($any($event))"
        ></crm-history>
      </div>
    </div>
  </form>
</crm-container>
