import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  IApiServiceConfig,
  API_SERVICE_CONFIG,
} from '../base/configuration/api-service-config';
import { Observable } from 'rxjs';
import { RequestTicketRequest, RequestTicketResponse } from './request';

@Injectable({ providedIn: 'root' })
export class ApiRequestsAdapterService {
  private readonly endpointUrl = this.config.url + 'tickets/requests';

  constructor(
    private http: HttpClient,
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig
  ) {}

  updateById(params: {
    newData: Partial<RequestTicketRequest>;
    ticketId: string;
  }) {
    return this.http.patch<RequestTicketResponse>(
      `${this.endpointUrl}/${params.ticketId}`,
      params.newData
    );
  }

  createNew(params: RequestTicketRequest): Observable<RequestTicketResponse> {
    return this.http.post<RequestTicketResponse>(`${this.endpointUrl}`, params);
  }

  getRequestTicketStatuses() {
    return this.http.get<RequestTicketResponse[]>(`${this.endpointUrl}/status`);
  }

  getById(params: Partial<RequestTicketRequest>) {
    return this.http.get(`${this.endpointUrl}/${params.id}`);
  }

  get(): Observable<RequestTicketRequest[]> {
    const params = { page: -1 };
    return this.http.get<RequestTicketRequest[]>(this.endpointUrl, { params });
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`${this.endpointUrl}/${id}`);
  }

  uploadImage(file: File) {
    const formData = new FormData();
    formData.append('file', file, `${file.name}`);
    return this.http.post<{ url: string }>(
      `${this.config.url}images`,
      formData
    );
  }

  getImage(id) {
    return this.http.get<{ url: string }>(`${this.config.url}images/${id}`);
  }

  sendTicketEmail(params: { id: string; emailsRecipes: string[] }) {
    return this.http.post<RequestTicketResponse>(
      `${this.endpointUrl}/${params.id}/email`,
      { recipients: params.emailsRecipes }
    );
  }
}
