<section *ngIf="reports$ | async as reports" class="reports">
  <div>
    <!-- New Report -->
    <div class="add-report-container">
      <button
        mat-stroked-button
        type="button"
        color="primary"
        (click)="generateProposal()"
        [attr.data-testid]="'create-report'"
      >
        <mat-icon>add_circle</mat-icon>
        {{ 'forms:' + actionLabel | i18nextCap }}
      </button>
    </div>

    <mat-card
      *ngFor="let report of getReportsSorted(); let i = index"
      class="report-item"
      [attr.data-testid]="'report-' + i"
    >
      <div class="report-item-left">
        <span>{{
          report.createdAt | momentTimezoneToAngularTimezone: 'DD/MM/YYYY'
        }}</span>
        <span>
          {{
            report.createdAt | momentTimezoneToAngularTimezone: 'HH:mm'
          }}</span
        >
        <mat-chip [ngClass]="'green-200'" *ngIf="report.signed">{{
          'forms:signed' | i18nextCap
        }}</mat-chip>
      </div>
      <div class="report-item-right">
        <div class="custom-icon">
          <button
            *ngIf="!isDetailPage && !report.signed"
            mat-icon-button
            class="color-opacity primary-color"
            type="button"
            (click)="assignReport(report, i)"
            [matTooltip]="'forms:sign' | i18nextCap"
            [attr.data-testid]="'sign-report-button'"
          >
            <div
              class="custom-icon"
              [ngClass]="{ 'green-icon': report.signed }"
            >
              <fa-icon [icon]="faFileSignature" size="xl"></fa-icon>
            </div>
          </button>
        </div>

        <div [matTooltip]="'preview' | i18nextCap">
          <button
            mat-icon-button
            class="color-opacity primary-color"
            (click)="onOpen({ reportId: report.id })"
            type="button"
          >
            <fa-icon [icon]="faEye" size="xl"></fa-icon>
          </button>
        </div>
        <div [matTooltip]="'previewInternal' | i18nextCap">
          <button
            *ngIf="reportType == 'SUPPORT_REPORT'"
            mat-icon-button
            class="color-opacity primary-color"
            type="button"
            (click)="onOpenInternal({ reportId: report.id })"
          >
            <fa-icon [icon]="faEyeSlash" size="xl"></fa-icon>
          </button>
        </div>

        <div
          [matTooltip]="
            (report.signed ? 'sendEmail' : 'cantSendEmailNotSigned')
              | i18nextCap
          "
        >
          <button
            mat-icon-button
            class="color-opacity primary-color"
            [disabled]="isDetailPage || !report.signed"
            (click)="openSendEmailDialog(report.id)"
            type="button"
          >
            <fa-icon [icon]="faEnvelope" size="xl"></fa-icon>
          </button>
        </div>
      </div>
    </mat-card>
  </div>
</section>
