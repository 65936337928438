<div class="header">
  <span class="mat-subtitle-2">{{ title | i18nextCap }}</span>
  <button
    mat-icon-button
    type="button"
    aria-label="Close"
    (click)="cancelForm()"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="body">
  <form>
    <crm-form-template
      [formGroup]="formGroup"
      [formFields]="formFields"
    ></crm-form-template>

    <div class="action-form-row">
      <div class="creation-info">
        <span *ngIf="intervention">
          {{ 'createdBy' | i18nextCap }}
          {{ getCreatedByName() }}
          {{ 'on' | i18next }}
          {{
            this.intervention.createdAt
              | momentTimezoneToAngularTimezone: 'MMMM D, YYYY HH:mm'
          }}
        </span>
      </div>
    </div>
  </form>
</div>
<div class="footer">
  <button
    *ngIf="!isNew"
    mat-button
    color="warn"
    type="button"
    name="delete"
    (click)="onDeleteAction(this.intervention.id)"
  >
    {{ 'delete' | i18nextCap }}
  </button>
  <button
    mat-flat-button
    color="primary"
    type="button"
    name="submit"
    (click)="onSubmit()"
  >
    {{ 'save' | i18nextCap }}
  </button>
</div>
