import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MaterialModule } from '@fullyops/shared/material.module';
import { I18NextModule } from 'angular-i18next';
import { EmailsInputComponent } from '../emails-input/emails-input.component';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ToastService } from '@fullyops/shared/services/toast/toast.service';

@Component({
  selector: 'fo-send-email-dialog',
  standalone: true,
  templateUrl: './send-email-dialog.component.html',
  styleUrl: './send-email-dialog.component.scss',
  imports: [
    CommonModule,
    MaterialModule,
    I18NextModule,
    EmailsInputComponent,
    ReactiveFormsModule,
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
  ],
})
export class SendEmailDialogComponent implements OnInit {
  constructor(
    protected dialogRef: MatDialogRef<
      SendEmailDialogComponent,
      EmailRecipients
    >,
    @Inject(MAT_DIALOG_DATA)
    protected data: SendEmailDialogData,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this.form.setValue(this.data.initialRecipients);
  }

  protected form = new FormGroup({
    to: new FormControl<string[]>([], { nonNullable: true }),
    bcc: new FormControl<string[]>([], { nonNullable: true }),
  });

  protected onSubmit() {
    if (this.form.value.to.length === 0) {
      this.toastService.showTranslatedErrorMessage(
        'error:youMustSelectAtLeastOneEmail'
      );
      return;
    }
    this.dialogRef.close(this.form.getRawValue());
  }
}

export interface SendEmailDialogData {
  title: string;
  okLabel: string;
  bcc: boolean;
  initialRecipients: EmailRecipients;
  suggestions?: string[];
}

export interface EmailRecipients {
  to: string[];
  bcc: string[];
}
