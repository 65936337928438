import { Injectable } from '@angular/core';
import { HotToastService, ToastPosition } from '@ngxpert/hot-toast';
import { I18NextPipe } from 'angular-i18next';
import {
  Breakpoints,
  ScreenBreakpointObserverService,
} from '@fullyops/shared/services/screen-breakpoints/screen-breakpoint-observer.service';

const COLORS = {
  error: '#da6b63',
  success: '#309f5c',
};

const DEFAULT_TOAST_OPTIONS = {
  duration: 3000,
  dismissible: true,
  closeStyle: {
    color: 'white',
    cursor: 'pointer',
    backgroundImage: `var(--hot-toast-close-btn-background-image, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e"))`,
  },
};

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(
    private toastService: HotToastService,
    private i18nextPipe: I18NextPipe,
    private screenBreakpointObserverService: ScreenBreakpointObserverService
  ) {}

  showSuccessMessage(message: string) {
    this.toastService.success(message, {
      ...DEFAULT_TOAST_OPTIONS,
      position: this.getToastPosition(),
      style: {
        color: 'white',
        backgroundColor: COLORS.success,
      },
      icon: '✅',
    });
  }

  showTranslatedSuccessMessage(key: string) {
    this.showSuccessMessage(this.i18nextPipe.transform(key));
  }

  showErrorMessage(message: string) {
    this.toastService.success(message, {
      ...DEFAULT_TOAST_OPTIONS,
      position: this.getToastPosition(),
      style: {
        color: 'white',
        backgroundColor: COLORS.error,
      },
      icon: '⚠️',
    });
  }

  showTranslatedErrorMessage(key: string) {
    this.showErrorMessage(this.i18nextPipe.transform(key));
  }

  showInvalidFieldsFormErrorMessage() {
    this.showTranslatedErrorMessage('forms:SomeFieldIsInvalid');
  }

  showNoChangesFormErrorMessage() {
    this.showTranslatedErrorMessage('forms:thereAreNoChanges');
  }

  private getToastPosition(): ToastPosition {
    const activeBreakpoints =
      this.screenBreakpointObserverService.screenSizeObserver.value;

    // Large screens
    if (
      activeBreakpoints.some((r) =>
        [Breakpoints.MD, Breakpoints.LG, Breakpoints.XL].includes(r)
      )
    ) {
      return 'bottom-right';
    }

    return 'top-center';
  }
}
